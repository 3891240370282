<template>
  <svg
    id="a"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 132.44 76.3"
    :style="`fill: ${logoColor};`"
  >
    <g>
      <path
        d="M55.9,76.3h-22.4l9.94-17.2c1.07-1.86,3.05-3,5.2-3h18.86s-11.6,20.2-11.6,20.2Z"
      />
      <polygon points="23.46 76.3 0 76.3 11.67 56.1 35.05 56.1 23.46 76.3" />
      <path
        d="M36.69,47.33l-19.91,.07,11.1-19.22,23.3-.08-9.3,16.22c-1.07,1.86-3.04,3.01-5.19,3.02Z"
      />
      <path
        d="M69.12,47.4h-18.95l9.54-16.33c1.08-1.84,3.05-2.97,5.18-2.97h18.88s-9.46,16.31-9.46,16.31c-1.07,1.85-3.05,2.99-5.19,2.99Z"
      />
      <path
        d="M52.85,19.4h-19.88S44.2,0,44.2,0h23.27s-9.42,16.39-9.42,16.39c-1.07,1.86-3.05,3.01-5.2,3.01Z"
      />
      <path
        d="M88.15,76.3h-22.23s9.94-17.2,9.94-17.2c1.07-1.86,3.05-3,5.2-3h18.69s-11.6,20.2-11.6,20.2Z"
      />
    </g>
    <g>
      <path
        d="M85.92,25.04c-.14,.1-.32,.18-.53,.24s-.44,.09-.67,.09c-.27,0-.5-.04-.69-.12-.2-.08-.36-.19-.48-.34s-.22-.32-.28-.53c-.06-.2-.09-.43-.09-.69,0-.55,.13-.96,.4-1.25,.27-.29,.65-.43,1.14-.43,.16,0,.32,.02,.48,.05,.16,.03,.3,.09,.43,.18s.23,.22,.31,.38c.08,.16,.12,.38,.12,.65,0,.14-.01,.3-.04,.47h-2.25c0,.19,.02,.35,.06,.5,.04,.14,.11,.27,.2,.37,.09,.1,.2,.18,.35,.23s.32,.08,.52,.08c.16,0,.32-.03,.48-.08,.16-.05,.28-.11,.36-.18l.21,.37Zm-1.2-2.57c-.28,0-.5,.07-.66,.2-.17,.13-.26,.36-.3,.68h1.74c0-.32-.07-.55-.2-.68-.13-.13-.33-.19-.57-.19Z"
      />
      <path
        d="M89.17,25.29v-1.82c0-.33-.05-.58-.16-.75-.11-.17-.3-.25-.58-.25-.25,0-.45,.06-.61,.18-.16,.12-.27,.27-.34,.46v2.18h-.56v-3.19h.39l.11,.39h.03c.11-.14,.26-.25,.45-.34,.19-.09,.41-.13,.67-.13,.19,0,.36,.02,.5,.06,.14,.04,.26,.11,.36,.21,.1,.1,.17,.24,.22,.41,.05,.17,.07,.39,.07,.65v1.93h-.56Z"
      />
      <path
        d="M93.43,25.44c0,.41-.12,.72-.36,.91-.24,.2-.6,.29-1.07,.29-.28,0-.5-.02-.68-.05-.17-.03-.32-.07-.44-.12l.15-.43c.11,.04,.23,.07,.36,.11,.13,.03,.3,.05,.51,.05,.2,0,.36-.02,.48-.05,.12-.03,.22-.08,.3-.16,.08-.07,.13-.17,.15-.29s.04-.26,.04-.42v-.31h-.03c-.09,.12-.21,.22-.36,.28s-.33,.1-.57,.1c-.47,0-.82-.13-1.04-.4-.22-.27-.33-.69-.33-1.25s.14-.96,.43-1.24c.28-.28,.7-.42,1.26-.42,.27,0,.49,.02,.68,.06,.19,.04,.36,.08,.51,.13v3.21Zm-1.41-.52c.24,0,.42-.05,.56-.16,.14-.11,.23-.28,.29-.51v-1.64c-.19-.08-.42-.12-.71-.12-.32,0-.57,.1-.75,.29-.18,.2-.27,.5-.27,.92,0,.18,.02,.34,.05,.49,.03,.15,.08,.28,.15,.38s.16,.19,.27,.25c.11,.06,.25,.09,.41,.09Z"
      />
      <path
        d="M94.51,21.13c0-.1,.04-.18,.11-.25s.16-.11,.28-.11,.21,.04,.29,.11c.08,.07,.12,.15,.12,.25s-.04,.18-.12,.24c-.08,.06-.17,.1-.29,.1s-.2-.03-.28-.1-.11-.15-.11-.24Zm.12,.97h.56v3.19h-.56v-3.19Z"
      />
      <path
        d="M98.63,25.29v-1.82c0-.33-.05-.58-.16-.75-.1-.17-.3-.25-.58-.25-.25,0-.45,.06-.61,.18-.16,.12-.27,.27-.34,.46v2.18h-.56v-3.19h.39l.1,.39h.03c.11-.14,.26-.25,.45-.34,.19-.09,.41-.13,.67-.13,.19,0,.36,.02,.5,.06,.14,.04,.26,.11,.36,.21,.1,.1,.17,.24,.22,.41,.05,.17,.07,.39,.07,.65v1.93h-.56Z"
      />
      <path
        d="M102.76,25.04c-.14,.1-.32,.18-.53,.24-.21,.06-.44,.09-.68,.09-.27,0-.5-.04-.69-.12-.2-.08-.36-.19-.48-.34s-.22-.32-.28-.53c-.06-.2-.09-.43-.09-.69,0-.55,.13-.96,.4-1.25,.27-.29,.65-.43,1.14-.43,.16,0,.32,.02,.48,.05,.16,.03,.3,.09,.43,.18s.23,.22,.31,.38c.08,.16,.12,.38,.12,.65,0,.14-.01,.3-.04,.47h-2.25c0,.19,.02,.35,.06,.5,.04,.14,.11,.27,.2,.37,.09,.1,.2,.18,.35,.23,.14,.05,.32,.08,.52,.08,.16,0,.32-.03,.48-.08,.16-.05,.28-.11,.36-.18l.21,.37Zm-1.2-2.57c-.28,0-.5,.07-.66,.2-.17,.13-.26,.36-.3,.68h1.73c0-.32-.07-.55-.2-.68-.14-.13-.33-.19-.57-.19Z"
      />
      <path
        d="M106.24,25.04c-.14,.1-.32,.18-.53,.24-.21,.06-.44,.09-.68,.09-.27,0-.5-.04-.69-.12-.2-.08-.36-.19-.48-.34s-.22-.32-.28-.53c-.06-.2-.09-.43-.09-.69,0-.55,.13-.96,.4-1.25,.27-.29,.65-.43,1.14-.43,.16,0,.32,.02,.48,.05,.16,.03,.3,.09,.43,.18s.23,.22,.31,.38c.08,.16,.12,.38,.12,.65,0,.14-.01,.3-.04,.47h-2.25c0,.19,.02,.35,.06,.5,.04,.14,.11,.27,.2,.37,.09,.1,.2,.18,.35,.23,.14,.05,.32,.08,.52,.08,.16,0,.32-.03,.48-.08,.16-.05,.28-.11,.36-.18l.21,.37Zm-1.2-2.57c-.28,0-.5,.07-.66,.2-.17,.13-.26,.36-.3,.68h1.73c0-.32-.07-.55-.2-.68-.14-.13-.33-.19-.57-.19Z"
      />
      <path
        d="M108.89,22.59c-.14-.04-.27-.06-.4-.06-.2,0-.36,.05-.48,.15s-.19,.23-.22,.38v2.24h-.56v-3.19h.39l.1,.39h.03c.09-.14,.19-.26,.32-.34,.12-.08,.28-.12,.47-.12,.14,0,.29,.02,.46,.06l-.12,.49Z"
      />
      <path
        d="M109.62,21.13c0-.1,.04-.18,.11-.25s.17-.11,.28-.11,.21,.04,.29,.11,.12,.15,.12,.25-.04,.18-.12,.24-.17,.1-.29,.1-.2-.03-.28-.1-.11-.15-.11-.24Zm.12,.97h.56v3.19h-.56v-3.19Z"
      />
      <path
        d="M113.74,25.29v-1.82c0-.33-.05-.58-.16-.75-.1-.17-.3-.25-.58-.25-.25,0-.45,.06-.61,.18-.16,.12-.27,.27-.34,.46v2.18h-.56v-3.19h.39l.1,.39h.03c.11-.14,.26-.25,.45-.34,.19-.09,.41-.13,.67-.13,.19,0,.36,.02,.5,.06,.14,.04,.26,.11,.36,.21,.1,.1,.17,.24,.22,.41,.05,.17,.07,.39,.07,.65v1.93h-.56Z"
      />
      <path
        d="M118,25.44c0,.41-.12,.72-.36,.91-.24,.2-.6,.29-1.07,.29-.28,0-.5-.02-.68-.05-.17-.03-.32-.07-.44-.12l.15-.43c.11,.04,.23,.07,.36,.11,.13,.03,.3,.05,.51,.05,.2,0,.36-.02,.48-.05,.12-.03,.22-.08,.3-.16,.08-.07,.13-.17,.15-.29s.04-.26,.04-.42v-.31h-.03c-.09,.12-.21,.22-.36,.28s-.33,.1-.57,.1c-.47,0-.82-.13-1.04-.4-.22-.27-.33-.69-.33-1.25s.14-.96,.43-1.24c.28-.28,.7-.42,1.26-.42,.27,0,.49,.02,.68,.06,.19,.04,.36,.08,.51,.13v3.21Zm-1.41-.52c.24,0,.42-.05,.56-.16,.14-.11,.23-.28,.29-.51v-1.64c-.19-.08-.42-.12-.71-.12-.32,0-.57,.1-.75,.29-.18,.2-.27,.5-.27,.92,0,.18,.02,.34,.05,.49,.03,.15,.08,.28,.15,.38s.16,.19,.27,.25c.11,.06,.25,.09,.41,.09Z"
      />
    </g>
    <g>
      <polygon points="77.48 .09 82.29 .09 71.06 19.4 66.25 19.4 77.48 .09" />
      <polygon
        points="130.19 3.63 132.44 .09 114.27 .09 112.21 3.63 119.23 3.63 110.06 19.4 114.87 19.4 124.04 3.63 130.19 3.63"
      />
      <polygon
        points="106.65 .09 93.99 9.04 91.74 .09 87.42 .09 76.19 19.4 80.95 19.4 88.13 7.04 90.21 15.45 102.23 6.95 94.99 19.4 99.75 19.4 110.97 .09 106.65 .09"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    logoColor: {
      type: String,
      default: "#1c3f7c",
    },
  },
};
</script>
