<template>
  <nav class="sidebar">
    <div class="sidebar__wrapper">
      <div class="sidebar__in">
        <div class="sidebar__top">
          <div class="sidebar__logo">
            <i class="icon-cog"></i>
            <div class="sidebar__logo-big">
              <main-logo logo-color="#fff"></main-logo>
            </div>
            <div class="sidebar__logo-small">Админка</div>
          </div>
          <div class="burger sidebar__burger" @click="sideBarClose">
            <div class="burger__in"></div>
          </div>
        </div>
        <div class="sidebar__menu">
          <div class="sidebar__title">Основное</div>
          <router-link
            to="/"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <a
              :href="href"
              @click="navigate"
              class="sidebar__item sidebar-nav-item"
              :class="{ active: isActive, 'exact-active-link': isExactActive }"
            >
              <i class="sidebar-nav-item__icon icon-list"></i>
              <div class="sidebar-nav-item__name">Главная страница</div>
            </a>
          </router-link>
          <router-link
            to="/menu"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <a
              :href="href"
              @click="navigate"
              class="sidebar__item sidebar-nav-item"
              :class="{ active: isActive, 'exact-active-link': isExactActive }"
            >
              <i class="sidebar-nav-item__icon icon-list"></i>
              <div class="sidebar-nav-item__name">Меню</div>
            </a>
          </router-link>
          <router-link
            to="/products"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <a
              :href="href"
              @click="navigate"
              class="sidebar__item sidebar-nav-item"
              :class="{ active: isActive, 'exact-active-link': isExactActive }"
            >
              <i class="sidebar-nav-item__icon icon-list"></i>
              <div class="sidebar-nav-item__name">Продукция</div>
            </a>
          </router-link>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import MainLogo from "../MainLogo.vue";
export default {
  components: {
    MainLogo,
  },
  methods: {
    sideBarClose() {
      document.body.classList.toggle("sidebar-is-closed");
    },
  },
};
</script>
