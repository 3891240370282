<template>
  <header class="header">
    <div class="container">
      <div class="header__in">
        <h1>{{ $route.name }}</h1>
        <div class="header__right">
          <div class="header__icons-list">
            <div
              class="dark-switch dark-switch--harizont dark-switch--light"
              @click="themeSwitch"
            >
              <i class="dark-switch__item icon-sun2"></i>
              <i class="dark-switch__item icon-moon1"></i>
            </div>
            <div class="adm-user">
              <a href="" class="hidden-link"></a>
              <div class="adm-user__ava icon-user"></div>
              <div class="adm-user__info">
                <div class="adm-user__name">root</div>
              </div>
            </div>
            <a class="icon-exit header__icon" @click.prevent="logout"></a>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import Cookies from "js-cookie";
export default {
  data() {
    return {
      darkTheme: false,
    };
  },
  mounted() {
    if (Cookies.get("darkTheme")) {
      this.darkTheme = true;
      document.body.classList.add("dark");
    }
  },
  methods: {
    themeSwitch() {
      if (this.darkTheme) {
        Cookies.remove("darkTheme");
        this.darkTheme = false;
      } else {
        Cookies.set("darkTheme");
        this.darkTheme = true;
      }
      document.body.classList.toggle("dark");
    },
    logout() {
      this.emitter.emit("logout");
    },
  },
};
</script>
