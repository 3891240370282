const apiServer = "https://tst.imt-filter.com/api";
export const getLogin = async (data, callBack) => {
  try {
    const response = await fetch(apiServer, {
      method: "POST",
      body: data,
    });
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }
    const result = await response.json();
    callBack(result);
  } catch (err) {
    console.log(err);
  }
};

export const checkToken = (callBack) => {
  fetch(apiServer, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.token_imt}`,
    },
  }).then((response) => callBack(response.ok));
};

export const getMenu = async (callBack) => {
  try {
    const response = await fetch(`${apiServer}/menu`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.token_imt}`,
      },
    });
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }
    const result = await response.json();
    callBack(result);
  } catch (err) {
    console.log(err);
  }
};

export const getMenuEdit = async (id, callBack) => {
  try {
    const response = await fetch(`${apiServer}/menu?id=${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.token_imt}`,
      },
    });
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }
    const result = await response.json();
    callBack(result);
  } catch (err) {
    console.log(err);
  }
};

export const sendMenuEdit = async (data, callBack) => {
  try {
    const response = await fetch(`${apiServer}/menu/save`, {
      method: "POST",
      body: data,
      headers: {
        Authorization: `Bearer ${localStorage.token_imt}`,
      },
    });
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }
    const result = await response.json();
    callBack(result);
  } catch (err) {
    console.log(err);
  }
};

export const getProducts = async (callBack) => {
  try {
    const response = await fetch(`${apiServer}/items`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.token_imt}`,
      },
    });
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }
    const result = await response.json();
    callBack(result);
  } catch (err) {
    console.log(err);
  }
};

export const getProduct = async (productId, callBack) => {
  try {
    const response = await fetch(`${apiServer}/items?id=${productId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.token_imt}`,
      },
    });
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }
    const result = await response.json();
    callBack(result);
  } catch (err) {
    console.log(err);
  }
};

export const sendProductForm = async (data, callBack) => {
  try {
    const response = await fetch(`${apiServer}/items/save`, {
      method: "POST",
      body: data,
      headers: {
        Authorization: `Bearer ${localStorage.token_imt}`,
      },
    });
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }
    const result = await response.json();
    callBack(result);
  } catch (err) {
    console.log(err);
  }
};

export const getMain = async (callBack) => {
  try {
    const response = await fetch(`${apiServer}/menu/main`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.token_imt}`,
      },
    });
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }
    const result = await response.json();
    callBack(result);
  } catch (err) {
    console.log(err);
  }
};
