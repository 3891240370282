import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    component: () => import("./../components/main/LoginPage.vue"),
  },
  {
    path: "/",
    name: "Главная страница",
    component: () => import("./../components/MainPage/MainPageBase.vue"),
  },
  {
    path: "/menu",
    name: "Меню",
    component: () => import("./../components/MenuPage/MenuBase.vue"),
  },
  {
    path: "/products",
    name: "Продукция",
    component: () => import("./../components/ProductsPage/ProductsBase.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
