<template>
  <default-page v-if="login"></default-page>
  <auth-page v-else></auth-page>
</template>
<script>
import { defineComponent } from "vue";
import DefaultPage from "./components/layouts/DefaultPage";
import AuthPage from "./components/layouts/AuthPage";
import { checkToken } from "@service-client/crm-service";

export default defineComponent({
  data() {
    return {
      login: false,
      url: "/",
    };
  },
  created() {
    this.url = window.location.pathname;
  },
  components: {
    DefaultPage,
    AuthPage,
  },
  mounted() {
    this.checkLogin();
    this.emitter.on("logout", () => {
      this.onLogout();
    });
    this.emitter.on("checkLogin", () => {
      this.checkLogin();
    });
  },
  methods: {
    checkLogin() {
      if (localStorage.token_imt) {
        checkToken((res) => {
          if (res) {
            if (this.url === "/login") {
              this.url = "/";
            }
            this.login = true;
            this.$router.push(this.url);
          } else {
            this.onLogout();
          }
        });
      } else {
        this.onLogout();
      }
    },
    onLogout() {
      if (localStorage.token_imt) {
        localStorage.removeItem("token_imt");
      }
      this.login = false;
      this.$router.push("/login");
    },
  },
});
</script>
