<template>
  <side-bar></side-bar>
  <div class="main-container">
    <main-header></main-header>
    <section class="main-section">
      <div class="adm-card-container">
        <router-view></router-view>
      </div>
    </section>
    <main-footer></main-footer>
  </div>
</template>
<script>
import MainHeader from "./../main/MainHeader";
import MainFooter from "./../main/MainFooter";
import SideBar from "./../main/SideBar";
export default {
  components: {
    MainFooter,
    MainHeader,
    SideBar,
  },
};
</script>
